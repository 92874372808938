<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 md:pt-40">

        <div class="max-w-6xl mx-auto text-left pb-12 md:pb-16">
          <h1 class="h1 mb-4">Próbálja ki ingyen</h1>
          <p class="text-xl text-gray-600">
            Ismerje meg a Trimble Connect alkalmazást azonnal és teljesen INGYEN.
          </p>
        </div>

        <div class="relative max-w-sm mx-auto grid gap-6 md:grid-cols-1 lg:grid-cols-2 items-start md:max-w-2xl lg:max-w-none mb-16">

          <div class="absolute top-1/2 w-full h-1 bg-blue-800 hidden lg:block" aria-hidden="true" data-aos="fade-right" data-aos-delay="450"></div>

          <a href="https://www.construsoft.com/hu/node/1176" target="_blank" data-aos="zoom-y-out">
          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl transform transition duration-500 hover:scale-105 hover:bg-gray-100">
            <div class="flex justify-center items-center h-12 w-12 rounded-full bg-blue-800 text-white font-bold mb-3">1</div>
            <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">Új Trimble ID létrehozása</h4>
            <p class="text-gray-600 text-center">Készítse el most a saját<br>Trimble ID fiókját ingyenesen!</p>
          </div>
          </a>

          <!-- <div data-aos="zoom-y-out" data-aos-delay="150">
            <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl transform transition duration-500 hover:scale-105 hover:bg-gray-100">
              <div class="flex justify-center items-center h-12 w-12 rounded-full bg-blue-800 text-white font-bold mb-3">2</div>
              <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">Aktivace</h4>
              <p class="text-gray-600 text-center">Potvrďte svůj email kliknutím<br>na odkaz zaslaný v emailu</p>
            </div>
          </div> -->

          <a href="https://app.connect.trimble.com/tc/app" target="_blank" data-aos="zoom-y-out" data-aos-delay="300">
          <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl transform transition duration-500 hover:scale-105 hover:bg-gray-100">
            <div class="flex justify-center items-center h-12 w-12 rounded-full bg-blue-800 text-white font-bold mb-3">2</div>
            <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">Bejelentkezés</h4>
            <p class="text-gray-600 text-center">Egyszerűen jelentkezzen be a meglévő<br>Trimble Connect hozzáférésével!</p>
          </div>
          </a>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DemoIntro',
}
</script>