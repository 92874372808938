<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">
      <!-- Page sections -->
      <DemoIntro />
      <DemoZakaznici />
        <div class="max-w-6xl mx-auto text-left pb-12 md:pb-16">
          <h1 class="h2">Árlista</h1>
          <p class="text-xl text-gray-600">
            A licencek megvásárlására számos és rugalmas lehetőséget kínálunk. Az egyfelhasználós licencektől kezdve az egy adott vállalatnak szóló komplex teljeskörű megoldásokig vagy egy adott projekthez szükséges számú licencek biztosításáig.
          </p>
        </div>
      <CenikInfo />    
    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import DemoIntro from './../partials/DemoIntro.vue'
import DemoZakaznici from './../partials/DemoZakaznici.vue'
import CenikInfo from './../partials/CenikInfo.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Demo',
  title: 'Próbálja ki ingyen!',
  components: {
    Header,
    DemoIntro,
    DemoZakaznici,
    CenikInfo,
    Footer,
  },
}
</script>
