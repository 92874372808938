<template>
  <section class="bg-gray-100">
    <div class="max-w-6xl mx-auto mb-6">
      <div class="pt-12 md:pt-16">

        <div class="max-w-4xl mx-auto text-center pb-12 md:pb-16">
          <p class="text-xl text-gray-600 pb-4">
            Ügyfeleink között már szerepelnek
          </p>

          <div class="grid grid-cols-3 md:grid-cols-6 gap-2 md:gap-6 content-center align-center justify-center">
            <div>
              <figure class="flex justify-center items-start" data-aos="zoom-y-out">
                <img :src="require('@/images/tc/reference/zlin.png')" class="h-1/4" alt="Zlínský kraj" />
              </figure>
            </div>

            <div>
              <figure class="flex justify-center items-start" data-aos="zoom-y-out">
                <img :src="require('@/images/tc/zakaznici/metrostav.svg')" class="h-1/4" alt="Metrostav" />
              </figure>
            </div>

            <div>
              <figure class="flex justify-center items-start" data-aos="zoom-y-out">
                <img :src="require('@/images/tc/reference/aquaprocon.png')" class="h-1/4" alt="AquaProcon" />
              </figure>
            </div>

            <div>
              <figure class="flex justify-center items-start" data-aos="zoom-y-out">
                <img :src="require('@/images/tc/reference/moravskoslezskykraj.png')" class="h-1/4" alt="Moravskoslezský kraj" />
              </figure>
            </div>

            <div>
              <figure class="flex justify-center items-start" data-aos="zoom-y-out">
                <img :src="require('@/images/tc/reference/afry.png')" class="h-1/4" alt="Afry" />
              </figure>
            </div>

            <div>
              <figure class="flex justify-center items-start" data-aos="zoom-y-out">
                <img :src="require('@/images/tc/reference/strabag.png')" class="h-1/4" alt="Strabag" />
              </figure>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DemoZakaznici',
}
</script>

<style>
.zakaznici {
  filter: grayscale(100%);
  transition: 3s filter linear;
}

.zakaznici:hover{
  filter: grayscale(0%);
}
</style>